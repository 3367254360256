import React, { useState, useEffect } from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ContactInfo from "../components/Contact/ContactInfo"
import BookingForm from "../components/BookACall/booking-form"
import axios from "axios"
import SEO from "../components/App/SEO"

const BookACall = () => {
  const [Broker, setBroker] = useState(null)
  useEffect(() => {
    const brokerId = new URL(window.location.href).searchParams.get("broker")
    if (brokerId) {
      axios
        .get(`${process.env.GATSBY_BACKEND_API_URL}/brokers/${brokerId}`)
        .then(results => {
          setBroker(results.data)
        })
        .catch(err => {
          setBroker(null)
        })
    }
  }, [])
  function clearBrokerState() {
    console.log("called")
    setBroker(null)
  }
  return (
    <Layout>
      <SEO
        title="Book a Call | Oxygen - Home Loans Made Simple"
        description="Book a call with our Loan Experts"
      />
      <Navbar clearBrokerState={clearBrokerState} />
      <PageBanner
        pageTitle="Book a Call"
        pageSubTitle={
          Broker
            ? "Book a call with our Loan Expert " +
              Broker.firstName +
              " " +
              Broker.lastName
            : "Book a call with one of our Home Loan Experts."
        }
        pageImage={Broker ? Broker.brokerImage : null}
      />
      <div className="container frm-contact pb-100">
        <div className="row">
          <div className="col-lg-8 col-12 pr-5">
            <BookingForm Broker={Broker} />
          </div>
          <div className="col-lg-4 col-12">
            <ContactInfo />
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default BookACall
